<template>
  <div
    class="tab-pane fade show active"
    id="home"
    role="tabpanel"
    aria-labelledby="home-tab"
  >
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">Liste des categories des partenaires</h3>
        </div>
        <div class="card-toolbar">
          <!--begin::Button-->
          <button
            @click="storeCategory"
            class="btn btn-success font-weight-bolder"
          >
            <i class="la la-plus"></i> Ajouter
          </button>
          <!--end::Button-->
        </div>
      </div>
      <b-card-body>
        <!--begin: Datatable-->
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-select
                style="width: 50px"
                v-model="perPage"
                :options="options"
                size="sm"
              ></b-form-select>
            </b-col>
            <b-col md="6">
              <b-form-input
                class="float-right mb-2"
                style="width: 200px;"
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder="Type to Search"
              >
              </b-form-input>
            </b-col>
          </b-row>
        </b-form>
        <b-row>
          <b-col>
            <b-table
              bordered
              hover
              outlined
              :items="categories"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              responsive="sm"
            >
              <template v-slot:cell(actions)="data">
                <button
                  class="btn btn-warning btn-sm mr-2"
                  @click="updateCategory(data.item.id)"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  class="btn btn-danger btn-sm"
                  @click="remove_category(data.item)"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rowsPartner"
              :per-page="perPage"
              :fields="fields"
              aria-controls="my-table"
              class="float-right mt-2"
            ></b-pagination>
          </b-col>
        </b-row>
        <!--end: Datatable-->
      </b-card-body>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  name: "Index",
  data() {
    return {
      create: "/new/category",
      source: "category",
      resource: "category",
      categories: [],
      // DataTable option
      filter: "",
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "id",
          label: "#"
        },
        {
          key: "nom",
          label: "Nom & Prénoms"
        },
        {
          key: "created_at",
          label: "Date Ajout",
          formatter: value => {
            return moment(String(value)).format("DD-MM-YYYY");
          }
        },
        "Actions"
      ],
      options: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" }
      ]
    };
  },
  beforeMount() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let vm = this;
      ApiService.get(this.source).then(function(response) {
        vm.categories = response.data.data;
      });
    },
    remove(category, url) {
      let vm = this;
      Swal.fire({
        title:
          "Vous êtes sûr de vouloir supprimer la catégorie " +
          category.nom +
          "?",
        text: "Il n'y aura aucun retour en arrière possible !",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: "Annuler"
      }).then(function(isConfirm) {
        if (isConfirm.isConfirmed) {
          ApiService.delete(`${url}/${category.id}`).then(function(response) {
            if (response.data.success) {
              Swal.fire({
                title: "Réussie",
                text: "Catégorie supprimé avec succès",
                icon: "success",
                confirmButtonText: "OK"
              });
              vm.fetchData();
            } else {
              Swal.fire({
                title: "Erreur",
                text: "Impossible de supprimé la categorie",
                icon: "error",
                confirmButtonText: "OK"
              });
            }
          });
        } else {
          Swal.fire({
            title: "Annuler",
            text: "Catégorie non supprimé :)",
            icon: "error",
            confirmButtonText: "OK"
          });
        }
      });
    },

    // Partenaire
    storeCategory() {
      this.formModal(`category`, "post");
    },
    updateCategory(id) {
      ApiService.get(`category/${id}/edit`).then(response => {
        this.formModal(`category/${id}`, "put", response.data.category.nom);
        this.fetchData();
      });
    },
    remove_category(category) {
      let url = "category";
      this.remove(category, url);
    },
    formModal(url, method, data = "") {
      Swal.fire({
        input: "text",
        inputLabel: "Nom de la catégorie",
        inputPlaceholder: "eg: ",
        inputValue: data,
        inputAttributes: {
          "aria-label": "Type your message here"
        },
        inputValidator: value => {
          if (!value) {
            return "Veillez saisir le nom svp!";
          }
        },
        showCancelButton: true
      }).then(isConfirm => {
        if (isConfirm.isDismissed) {
          Swal.fire({
            title: "Erreur",
            text: "Votre catégorie n'a pas été enregistrée!",
            icon: "error",
            confirmButtonText: "Ok"
          });
        }
        if (isConfirm.isConfirmed) {
          if (method === "post")
            ApiService.post(url, { nom: isConfirm.value }).then(response => {
              if (response) {
                Swal.fire({
                  title: "Success",
                  text: "Votre catégorie a été enregistrée avec success !",
                  icon: "success",
                  confirmButtonText: "Ok"
                }).then(isConfirm => {
                  if (isConfirm) {
                    this.fetchData();
                  }
                });
              }
            });
          else
            ApiService.put(url, { nom: isConfirm.value }).then(response => {
              if (response) {
                Swal.fire({
                  title: "Success",
                  text: "Votre catégorie a été modifiée avec success !",
                  icon: "success",
                  confirmButtonText: "Ok"
                });
                this.fetchData();
              }
            });
        }
      });
    }
  },
  computed: {
    rowsPartner() {
      return this.categories.length;
    }
  }
};
</script>

<style scoped></style>
